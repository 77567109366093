<template>
         <section class="page_title  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>Message from the Principal Investigator</h6>
                           <h1>Principal Investigator</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item"><a href="#">About</a></li>
                              <li class="bread_crumb-item active"> Principal Investigator's Message</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>

            <section class="about type_two pdt_30 pdb_100">
               <div class="container">
                  <div class="empty_space"></div>
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-6 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="about_content">
                           <div class="heading tp_one">
                              <h6>Principal Investigator</h6>
                              <h2>Message from the Principal Investigator</h2>
                           </div>
                           <p style="text-align:justify">During this global crisis, which has evolved at an unprecedented pace, and whose duration we cannot
foresee, many of us are naturally concerned for our loved ones, particularly those who are the most
vulnerable, because of age or health related factors. Crises like the COVID-19 pandemic have undermined and undercut essential health services across the
globe and in many countries, the healthcare system got overwhelmed.</p>
                          
                           <p style="text-align:justify"> The emergence of the COVID-19 pandemic has brought to the fore the need for institutions to share
expertise, and work collaboratively across African countries and regions as well as globally to effectively
respond to epidemic challenges while balancing these with persisting systems deficiencies. Regarding COVID-19 response interventions, the COVID-19 assessment project draws its line of focus
towards key response levers like testing and surveillance, health systems resilience, non-pharmaceutical
interventions, gender and socio-economic vulnerabilities, vaccine preparedness, and epidemic control
among others.  </p>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-6 col-md-12">
                        <!---------col----------->
                        <div class="image_box pdt_30" >
                           <img src="assets/image/resources/dr-rhoda-wanyenze-sph-01.jpg" style=" border-top-left-radius: 40px; border-bottom-right-radius: 40px;" class="img-fluid" alt="img">
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->

                     <div class="col-lg-12 col-md-12">
                        <!---------col----------->
                        <p style="text-align:justify">To foster cross country learning and evidence synthesis, our project is in partnership with several universities from the eastern, central, and western Africa including the University of Cheikh Anta Diop
Dakar, Senegal; the University of Ibadan, Nigeria; the University of Kinshasa, DRC; and the University of
Ghana. This important work would not be possible without funding from the Bill and Melinda Gates Foundation
as well as the Gate Ventures. We plan to continue hosting discussion platforms and tracking the COVID-
19 throughout the COVID-19 pandemic in order to learn and foster learning health systems. We hope to build capabilities that will play a key role in developing modelling capacities in Africa to help monitor and enhance efforts and control measures during all stages of future epidemics. We are confident that by working together with Ministries of Health, Development partners, Health workers and communities, we will overcome this crisis do so in a manner that safeguards the safety and
protection of our personnel, and that of the people we serve. It is a great honor and privilege for me to lead and serve together with all our partners on this project.
                           
                           </p>
                        <!---------col-end---------->
                     </div>
                  </div>
               </div>
            </section>
</template>